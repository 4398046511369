import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {Container, Col, Row, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { far } from '@fortawesome/free-regular-svg-icons';
import { faUser,faLock,faLongArrowAltLeft,faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as Yup from "yup";
import axios from 'axios'
export default class ForgotPassword extends Component{
	constructor(props) {
		super(props);
		this.state = {
			message:'',
			status:''
		};
	}
	closeMessage=()=>{
		this.setState({message:''})
	}
	onSubmit = (submitData) => {
		submitData.url=window.location.href
		const headers = { "content-type": "application/vnd.api+json" ,'cache-control':'no-cache'}
		axios.post('https://app.lonestarfruitandvegetable.com/lonestar_admin/user/api/forgotPassword', submitData,{headers}).then((response) => {
		if (response.data !== undefined) {
			this.setState({message:response.data.message,status:response.data.status})
		}
			}).catch(error => {
				console.log('error in forgot password')
				console.log(error)
		})
	}

	render(){
		return(
			<>
				<div className="login-wrap">	
					<Container>
						<Row>
							<Col xs md="8" className="m-auto">
								
								<div className="login-container">
									<div className="left-img">
										<div className="heading-content">
											<h2 className="text-center text-light">Lone<br/>Star</h2>
											<small>From Our Family To Yours!</small>
										</div>
									</div>
									<div className="right-form">
										<Formik className=""
									        initialValues={{ email: '', password: '' }}
											validationSchema={Yup.object().shape({
												username: Yup.string().required("Please Enter User Name"),
												email: Yup.string().required("Please Enter Email").email(),
											})}
											onSubmit={(values) => {
												this.onSubmit(values);
											}}
									    >
									       {({
									         values,
									         errors,
									         touched,
									         handleChange,
									         handleBlur,
									         handleSubmit,
									         isSubmitting,
									         /* and other goodies */
									       }) => (
									        <form onSubmit={handleSubmit}>
									        	<h4 className="mb-4">Forgot Password</h4>
												{this.state.message !==''
													&&
													<div className={'alert '+(this.state.status?'alert-success':'alert-danger')}>
														<label >
																{this.state.message}
															</label>
															<button type="submit" className='close close_forgot_pass' onClick={this.closeMessage}>
																x
																
															</button>
													</div>
												}
										        <div className="form-group form-gp">
											        <input
											            type="text"
											            name="username"
											            onChange={handleChange}
											            onBlur={handleBlur}
														value={values.username}
											            className="form-control"
											            placeholder="User Name"
											        />
										            <div className="login-icon">
														<FontAwesomeIcon icon={faUser} />
													</div>
													<label className="err-msg">
										            	{errors.username && touched.username && errors.username}
										            </label>
										        </div>
										        <div className="form-group form-gp">
											        <input
											            type="email"
											            name="email"
											            onChange={handleChange}
											            onBlur={handleBlur}
											            value={values.email}
											            className="form-control"
														placeholder="Email"
											        />
										            <div className="login-icon">
														<FontAwesomeIcon icon={faEnvelope} />
													</div>
													<label className="err-msg">
										           	{errors.email && touched.email && errors.email}
										           	</label>
										        </div>
												<div className="login-btn">
													<button
														type="submit"
														className="loginBtn"
													>
														Send
													
													</button>
												</div>
						
										        <div className="form-group form-gp">
										        	<Link to="/" className="forgot-link"><FontAwesomeIcon icon={faLongArrowAltLeft} /> Back</Link>
										        </div>
									        </form>
									       )}
									    </Formik>
								    </div>
							    </div>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		)
	}
}
